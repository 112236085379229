<template>
	<div class="material-box">
		<div class="material">
			<div class="between material-wezhibox">
				<div class="align-center" style="color: #c8c8c8">
					<i class="el-icon-location-outline"></i>
					当前位置：首页> IP素材 >素材名称
				</div>
				<!-- <div class="Secrch align-center">
          <i class="el-icon-search"></i>
          搜索
        </div> -->
			</div>
			<div class="headers">
				<div class="flex">
					<div class="headers-leftimg"><img :src="detali.image" alt="" /></div>
					<div class="right-detail">
						<div class="detali_titles">{{ detali.title }}</div>
						<div class="flex">
							权力方：
							<div>
								{{ detali.power }}
							</div>
						</div>
						<div class="flex">
							类别：
							<div>
								{{ detali.cate_title }}
							</div>
						</div>
						<div>
							权力方身份：
							<span v-if="detali.power_type == 1">唯一版权方</span>
							<span v-if="detali.power_type == 2">代理</span>
							<!-- {{ detali.Identity }} -->
						</div>
						<div class="flex">
							简介：
							<div v-html="detali.remark"></div>
						</div>
						<div class="flex">
							<div class="application font-two" @click="sqSubmit()">
								申请授权 <i class="el-icon-right"></i>
							</div>
						</div>
					</div>
				</div>

				<div class="tab-list flex">
					<div class="tab-item" :class="{ selectTab_item: SelectTab == 1 }" @click="SelectTab1(1)">
						IP素材详情介绍
					</div>
					<div class="tab-item" :class="{ selectTab_item: SelectTab == 2 }" @click="SelectTab1(2)">
						IP素材确权证书
					</div>
				</div>
			</div>
			<div style="width:100%" class="footer-img" v-if="SelectTab == 1">
				<div style="width:100%;word-wrap:break-word;padding: 0 30px;word-break: normal;" v-html="detali.content"></div>
			</div>
			<div class="footer-img" v-if="SelectTab == 2">
				<img :src="detali.pic" alt="" />
			</div>
			<div></div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				SelectTab: 1,
				detali: {},
			};
		},
		async created() {
			const {
				data
			} = await this.$api({
				c: "index",
				a: "sucai_detail",
				id: this.$route.query.id,
			});
			//console.log(data.re);
			this.detali = data.re;
			var pic_content=data.re.content.replace(/<img/g,"<img style='max-width:100%;height:auto;'");
			this.detali.content = pic_content;
		},
		methods: {
			SelectTab1(e) {
				if (e == 2) {
					this.detailimg = this.detailimg1;
				} else {
					this.detailimg = require("@/assets/test/test_Icon13.png");
				}
				this.SelectTab = e;
			},
			sqSubmit() {
				var brand_code = this.detali.brand_code;
				this.$router.push({
					path: "/index/apply_add",
					query: {
						is_type: 2,
						brand_code: brand_code,
						id: this.$route.query.id,
					},
				});
			}
		},
	};
</script>

<style lang="scss" scoped>
	.material-box {
		height: 100%;
		background: rgb(248, 248, 248);
		padding-bottom: 60px;

		.material {
			width: 1200px;
			margin: 0 auto;
			background: #ffffff;
			padding-bottom: 60px;
		}

		.material-wezhibox {
			padding: 14px 15px;
			border-bottom: 1px solid #dfdfdf;
			margin-bottom: 30px;
		}

		.Secrch {
			border-radius: 100px;
			padding: 4px 24px;
			border: 1px solid rgb(0, 118, 254);
			color: rgb(0, 118, 254);

			i {
				margin-right: 5px;
			}
		}
	}

	.tab-list {
		.tab-item {
			width: 100px;
			height: 50px;
			line-height: 50px;
			margin-right: 10px;
			border-bottom: 2px solid #ffffff;
			text-align: center;
		}

		.selectTab_item {
			border-bottom: 2px solid rgb(0, 118, 254);
		}
	}

	.headers {
		padding: 43px 0px 50px 30px;

		.application {
			cursor: pointer;
			color: rgb(0, 118, 254);
			padding: 9px 40px;
			border: 1px solid rgb(0, 118, 254);
			margin: 50px 0;
		}

		.right-detail {
			white-space: pre-wrap;
			color: #676767;
			font-size: 13px;
			line-height: 30px;
			display: flex;
			flex-direction: column;

			.detali_titles {
				color: #212121;
				font-size: 18px;
				margin-bottom: auto;
			}
		}

		.headers-leftimg {
			margin-right: 40px;
		}
	}

	.footer-img {
		display: flex;
		justify-content: center;

		img {
			object-fit: cover;
		}
	}
</style>
